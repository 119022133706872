import gql from "graphql-tag";

export const GET_HOMEPAGE = gql`
  query ($locale: I18NLocaleCode!, $publicationState: PublicationState) {
    homepage(locale: $locale, publicationState: $publicationState) {
      data {
        id
        attributes {
          background_images {
            id
            desktop_background {
              data {
                id
                attributes {
                  alternativeText
                  caption
                  mime
                  url
                }
              }
            }
            mobile_background {
              data {
                id
                attributes {
                  alternativeText
                  caption
                  mime
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
