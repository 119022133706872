<template>
  <div class="homepage">
    <main id="desktop-wrapper" class="h-screen hidden sm:flex">
      <div class="logo-animations">
        <div>Felix</div>
        <div>JH</div>
        <div>Stumpf</div>
      </div>

      <NuxtImg
        v-for="(image, idx) in pagesImages"
        class="gallery-image"
        :class="`gallery-image-${idx}` + `${idx === 0 ? ' active' : ''}`"
        :key="image.id + '_' + idx"
        sizes="sm:600px md:100vw"
        :src="image.desktop_background.data?.attributes?.url"
        alt=""
        @click="updateOnClick"
      />

      <div class="home-link">
        <NuxtLink :to="localPath('/work')">Work</NuxtLink>
      </div>
    </main>

    <main id="mobile-wrapper" class="h-screen flex sm:hidden">
      <div class="logo-animations">
        <div>Felix JH Stumpf</div>
      </div>

      <NuxtImg
        v-for="(image, idx) in pagesImages"
        class="gallery-image"
        :class="`gallery-image-${idx}` + `${idx === 0 ? ' active' : ''}`"
        :key="image.id + '_' + idx"
        sizes="sm:600px md:100vw"
        :src="image.mobile_background.data?.attributes?.url"
        alt=""
        :loading="idx === 0 ? 'eager' : 'lazy'"
        @click="updateOnClick"
      />

      <div class="home-link">
        <NuxtLink :to="localPath('/work')">Work</NuxtLink>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
// Graphql query
import { GET_HOMEPAGE } from "@/gql/Homepage";

// Types
import type { Query, ComponentBlockBackgroundImages } from "@/types/Strapi";

// Auth/Preview mode
const previewModeStore = usePreviewModeStore();
const { previewMode } = storeToRefs(previewModeStore);

// i18n / Language
const { t } = useI18n();
const locale = ref(useI18n().locale);
const localPath = useLocalePath();

// Set meta tags with main ones from pinia
setMeta(true);

////// Fetch homepage data :

const pagesImages = ref<Array<ComponentBlockBackgroundImages>>();
const backgroundImages = ref({
  idx: 0,
  maxLength: 0,
});

// Handle query (data & error) function
const handleQuery = async (data: any, error: any) => {
  try {
    // Graphql error + not found handling
    if (
      error.value?.message ||
      data.value.homepage.data.attributes.background_images === 0
    ) {
      throw error;
    }

    // Get page data
    pagesImages.value = data.value.homepage.data.attributes
      .background_images as ComponentBlockBackgroundImages[];

    // Set initial background images
    backgroundImages.value.maxLength = pagesImages.value.length;

    // Catch errors
  } catch (error: any) {
    // Throw error pretty-formated for error handler (error.vue), default is page not found
    throw showError({
      statusCode: error.value?.statusCode ?? 404,
      statusMessage: error.value?.message ?? t("error_404"),
      stack: error.value?.stack ?? "",
      fatal: true,
    });
  }
};

////// Background images transitions :

let intervalId: ReturnType<typeof setTimeout>;

const updateBackgroundImages = () => {
  if (pagesImages.value) {
    // Increment index
    backgroundImages.value.idx = backgroundImages.value.idx + 1;

    // Current image
    const currentImage = document.getElementsByClassName(
      `gallery-image-${backgroundImages.value.idx - 1}`
    );

    Array.from(currentImage).forEach((element) => {
      element.classList.remove("active");
    });

    // Check if reached end of array
    if (backgroundImages.value.idx >= backgroundImages.value.maxLength) {
      // Reset index
      backgroundImages.value.idx = 0;
    }

    // Next image
    const nextImage = document.getElementsByClassName(
      `gallery-image-${backgroundImages.value.idx}`
    );

    Array.from(nextImage).forEach((element) => {
      element.classList.add("active");
    });
  }
};

// Update background image on click
const updateOnClick = () => {
  // Reset timer
  clearInterval(intervalId);
  intervalId = setInterval(() => {
    updateBackgroundImages();
  }, 3006);

  updateBackgroundImages();
};

// Background images timer
onMounted(() => {
  intervalId = setInterval(() => {
    updateBackgroundImages();
  }, 3006);
});

onUnmounted(() => clearInterval(intervalId));

// Fetch on setup
const { data, error } = await useAsyncQuery<Query>(GET_HOMEPAGE, {
  locale: locale.value,
  publicationState: previewMode.value ? "PREVIEW" : "LIVE",
});
handleQuery(data, error);

// Watch for locale changes to translate page
watch(
  () => [locale.value, previewMode.value],
  async () => {
    const { data, error } = await useAsyncQuery<Query>(GET_HOMEPAGE, {
      locale: locale.value,
      publicationState: previewMode.value ? "PREVIEW" : "LIVE",
    });
    handleQuery(data, error);
  }
);

// Preload next route
preloadRouteComponents("/work");
</script>
